import { DefaultLocationsPage } from '../../components/default-locations-page';
import { TransactionViewWrapper } from '../../components/view-transactions/transactions-view-wrapper';
import type { GetServerSidePropsContext } from 'next';
import {
  serverSideGeocodePageQuery,
  serverSideAllRegionsQuery,
  serverSideFeatureToggles_FeatureConfigsQuery,
} from '../../gql/queries';
import { AppProvider } from '../../providers/app-provider';
import { serverSideProps, getCustomHeaders } from '../../utils/serverSideProps';
import { COMMON_NAMESPACES, LOCATIONS_NAMESPACES, REACT_QUERY_CONFIG } from '../../constants';
import { getServerSideClients, getServerSideSafeLanguage } from '@dx-ui/framework-react-query';
import { FilterProvider } from '../../providers/filter-provider';
import { getFeatureConfig } from '@dx-ui/utilities-feature-toggles-configs';

export default function DefaultLocations({ isOneLink }: { isOneLink: boolean }) {
  return (
    <FilterProvider>
      <AppProvider isOneLink={isOneLink}>
        <DefaultLocationsPage />
        <TransactionViewWrapper />
      </AppProvider>
    </FilterProvider>
  );
}

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const { res } = context;
  const customHeaders = getCustomHeaders(context);
  const { queryClient } = getServerSideClients({
    ...REACT_QUERY_CONFIG,
    customHeaders,
    response: res,
  });
  const translateAutocompleteConfig = await serverSideFeatureToggles_FeatureConfigsQuery(
    queryClient,
    {
      names: ['config-ui-translate-autocomplete'],
    }
  ).catch((e) => {
    console.log(`Error in serverSideFeatureToggles_FeatureConfigsQuery: ${e}`); // eslint-disable-line no-console
    return null;
  });
  const configLanguagesData = translateAutocompleteConfig
    ? getFeatureConfig<{ languages: string[] }>({
        featureToggleData: translateAutocompleteConfig,
        name: 'config-ui-translate-autocomplete',
      })
    : null;
  const configLanguages = configLanguagesData?.languages || [];
  const requestedLanguage = getServerSideSafeLanguage(context);
  const isSupportedLanguage = configLanguages.includes(requestedLanguage);
  const language = isSupportedLanguage ? requestedLanguage : 'en';

  return serverSideProps({
    context,
    namespaces: [...COMMON_NAMESPACES, ...LOCATIONS_NAMESPACES],
    getServerSideQueries({ queryClient }) {
      return [
        serverSideAllRegionsQuery(queryClient, { language }).catch((e) => {
          console.log(`Error in serverSideAllRegionsQuery: ${e}`); // eslint-disable-line no-console
          return null;
        }),
        serverSideGeocodePageQuery(queryClient, {
          language,
          pageType: 'DEFAULT',
          path: '/locations',
        }).catch((e) => {
          console.log(`Error in serverSideGeocodePageQuery: ${e}`); // eslint-disable-line no-console
          return null;
        }),
      ];
    },
  });
};
