//These types are stub types until backend is ready to go

export type SendGenAiMessageVariables = {
  conversationId: string;
  sessionId: string;
  guestId: number;
};
type Mutation = {
  createConversationGenAi(guestId: number): GuestConversationGenAi;
  sendGenAiMessage(variables: SendGenAiMessageVariables): GuestMessageGenAi;
};

export type GuestConversationGenAi = {
  id: string;
  content: GuestMessageContentGenAi[];
  role: 'assistant' | 'user';
};

export type GuestMessageGenAi = {
  messageId: string;
  messageType: GuestMessageTypeGenAi;
  content: GuestMessageContentGenAi;
  createdAt: string;
  role: 'assistant' | 'user';
};

export type GuestMessageContentGenAi = {
  message: string;
  type: GuestMessageTypeGenAi;
};

export type GuestMessageTypeGenAi = 'welcome' | 'response' | 'bubbles';

export const CreateConversationGenAiDocument = {
  operationName: 'createConversationGenAi',
  operationString: `query createConversationGenAi($guestId: [String]!) {
  conversation(guestId: $guestId) {
id
content
  }
}`,
  originalOpName: 'createConversationGenAi',
};

export const SendGenAiMessageDocument = {
  operationName: 'sendGenAiMessage',
  operationString: `mutation sendGenAiMessage($conversationId: String!, $sessionId: String!, $guestId: Number!) {
  genAiMessage(
    guestId: $guestId
    sessionId: $sessionId
    guestId: $guestId
  ) {
    data
    error {
      code
    }
  }
}`,
  originalOpName: 'sendGenAiMessage',
};
