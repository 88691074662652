import { useTranslation } from 'next-i18next';
import { BROWSE_BY_AMENITY_TILES, GOALS } from '../../../constants';
import { useGetMVTSelections, useWrappedRouter } from '../../../hooks';
import { getAmenityMVTVariant, getAmenityTiles } from './amenity-tiles-utils';
import { DynamicGrid } from '@dx-ui/osc-dynamic-grids';
import { sendReward } from '@dx-ui/framework-conductrics';

export const AmenityTiles = ({ hasScrolledToMVT }: { hasScrolledToMVT: boolean }) => {
  const {
    router: { locale },
  } = useWrappedRouter();
  const { t } = useTranslation('default-locations-page');
  const { isLoaded, isMVTVariant } = useGetMVTSelections({
    agentId: BROWSE_BY_AMENITY_TILES.agentId,
    isProvisional: hasScrolledToMVT,
    MVTVariants: ['b', 'c', 'd', 'e'],
  });
  const variantName = getAmenityMVTVariant(isMVTVariant);
  const { items, heading } = getAmenityTiles({ type: variantName, t, locale }) ?? {};
  return isLoaded && !!items?.length ? (
    <div className="pt-8">
      <h2 className="text-primary heading-2xl sm:heading-4xl flex items-center pb-4 leading-snug">
        {t('attributeTiles.exploreTheWorld')}
      </h2>
      <h3 className="flex items-center text-lg font-bold leading-snug sm:text-xl ">{heading}</h3>
      <DynamicGrid
        items={items}
        id={variantName ?? ''}
        imageAspectRatio="4:3"
        className="px-0 !pt-4"
        onViewItem={() => sendReward(GOALS.amenityTileEngagement)}
      />
    </div>
  ) : null;
};
