import * as React from 'react';
import { ChatBubble } from './genai-chat.chat-bubble';
import { Markdown } from '@dx-ui/osc-markdown';

export const ChatBubbleMarkdown = React.forwardRef<
  HTMLLIElement,
  { children: string; chatRole: 'user' | 'assistant' }
>(({ chatRole, children }, ref) => {
  return (
    <ChatBubble ref={ref} chatRole={chatRole}>
      <Markdown language="en" origin="https://www.test.hilton.com">
        {children}
      </Markdown>
    </ChatBubble>
  );
});

ChatBubbleMarkdown.displayName = 'ChatBubbleMarkdown';
