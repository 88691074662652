import type { LocationContentBlock } from '@dx-ui/gql-types';
import { HalfAndHalf } from '@dx-ui/osc-half-and-half';
import { decodeHtmlEntities, getContentBlockData } from './content-block-utils';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';

export const ContentBlock = ({
  content,
  isReverseLayout,
}: {
  content?: LocationContentBlock;
  isReverseLayout?: boolean;
}) => {
  const { t } = useTranslation('default-locations-page');
  if (!content) return null;
  const { title, descriptions } = content;
  const description = decodeHtmlEntities(descriptions[0]);
  const { imageUrl, imageAltTxtKey } = getContentBlockData(title) ?? {};
  return (
    <div
      className={cx('flex justify-center', {
        'bg-bg-light': isReverseLayout,
      })}
    >
      <HalfAndHalf
        headline={title}
        description={description}
        imageUrl={imageUrl}
        imageAltText={imageAltTxtKey ? t(imageAltTxtKey) : ''}
        hasXLayoutInversed={isReverseLayout}
        headlineClassName="lg:!heading-4xl"
        descriptionClassName="lg:!text-xl"
        hasYLayoutInversed
      />
    </div>
  );
};
