import { GenaiChat } from '@dx-ui/osc-genai-chat';
import { useState } from 'react';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { useFeatureToggles_FeatureConfigsQuery } from '../../../gql/queries';
import { getFeatureConfig } from '@dx-ui/utilities-feature-toggles-configs';

/** This component will render a banner that allows the customer to interact with our generative ai dialog.
 *  Eligibility to use this feature is determined by whether their honors number is in a config toggle TBD
 *  DO NOT ENABLE THE FEATURE QUERY UNTIL THE TOGGLE LOGIC IS FINALIZED. THIS FEATURE IS NOT READY FOR PRODUCTION
 */
export const GenAIBannerAndDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { guestId, isLoading: isAuthLoading } = useAuth();
  const {
    data: featureToggleData,
    isLoading,
    isError,
  } = useFeatureToggles_FeatureConfigsQuery({ names: ['enable-genai-chat-ui'] });

  const handleDismiss = () => {
    setIsOpen(false);
  };
  const handleClick = () => {
    setIsOpen(true);
  };

  const isGenaiChatEnabled = getFeatureConfig<{ guestIds?: number[] }>({
    featureToggleData,
    name: 'enable-genai-chat-ui',
  })?.guestIds?.includes(guestId ?? 0);

  if (isLoading || isAuthLoading || isError || !isGenaiChatEnabled) return null;

  return (
    <div className="sm:px-10" role="banner">
      <div className="bg-bg-primary flex items-start gap-x-3 px-4 py-2 sm:items-center sm:justify-center sm:p-2">
        <svg className="size-6" aria-hidden>
          <g id="Bounding_Box">
            <path fill="none" d="M0,0v24h24V0H0Z" />
          </g>
          <g id="Outline">
            <path d="M20.167,16.02c-.479-.093-1.186-.322-1.84-.888-.895-.772-1.199-1.744-1.302-2.255-.046-.23-.247-.398-.482-.402h-.008c-.231,0-.434.16-.486.386-.121.514-.459,1.491-1.373,2.269-.508.432-1.126.741-1.788.894-.227.052-.388.254-.388.488s.161.435.389.487c.461.106,1.146.347,1.787.894.914.777,1.252,1.755,1.373,2.269.053.226.255.386.486.386h.008c.235-.004.437-.171.482-.402.103-.511.407-1.483,1.302-2.255.654-.565,1.361-.795,1.84-.888.234-.045.404-.251.404-.491s-.17-.445-.404-.491ZM17.673,17.132c-.538.465-.906.987-1.157,1.474-.264-.487-.644-1.01-1.191-1.477-.279-.238-.586-.445-.911-.619.326-.174.632-.382.911-.619.548-.466.928-.989,1.191-1.477.251.487.619,1.009,1.157,1.474.301.26.61.463.911.622-.301.159-.61.362-.911.622Z" />
            <path d="M15.354,11.353l5-5.025c.031-.031.049-.07.07-.107.012-.02.03-.037.039-.058.024-.058.035-.12.035-.182,0-.002.001-.004.001-.006,0-.128-.049-.256-.146-.354l-2-2c-.047-.047-.103-.084-.164-.109-.061-.025-.125-.038-.19-.038h0c-.011,0-.021.006-.032.006-.053.004-.107.011-.157.031-.047.019-.087.051-.126.084-.012.01-.026.014-.037.025L2.646,18.622c-.195.195-.195.512,0,.707l2,2c.098.098.226.146.354.146s.256-.049.354-.146l9.925-9.925c.025-.018.054-.029.076-.051ZM13.706,8.999l4.295-4.316,1.293,1.293-4.295,4.316-1.293-1.293Z" />
            <path d="M7.888,5.987c.405.093.784.282,1.094.546.56.476.766,1.074.839,1.388.054.227.255.386.487.386h.008c.235-.004.437-.171.482-.402.062-.312.249-.905.792-1.375.4-.345.831-.484,1.121-.541.235-.045.405-.251.405-.491s-.17-.445-.405-.491c-.29-.057-.721-.196-1.12-.541-.544-.47-.73-1.063-.793-1.375-.046-.231-.247-.398-.482-.402-.255,0-.44.157-.495.386-.073.314-.279.913-.839,1.388-.31.264-.688.453-1.094.546-.227.052-.388.254-.388.487s.161.435.388.487ZM9.63,5.228c.279-.238.495-.498.662-.752.161.254.37.513.646.75.12.104.243.195.365.274-.122.08-.245.17-.366.274-.274.237-.484.496-.645.75-.167-.254-.383-.514-.662-.752-.115-.098-.238-.189-.365-.272.127-.083.25-.174.365-.272Z" />
            <path d="M2.974,11.255c.502.116.971.35,1.354.677.691.588.948,1.329,1.04,1.718.053.227.255.386.486.386h.009c.235-.004.436-.172.481-.402.077-.386.307-1.12.983-1.704.494-.427,1.028-.601,1.39-.671.234-.045.404-.251.404-.491s-.17-.445-.404-.491c-.361-.07-.896-.244-1.39-.67-.677-.584-.906-1.318-.983-1.704-.046-.23-.246-.398-.481-.402-.238-.024-.442.156-.495.385-.092.39-.349,1.131-1.04,1.719-.484.413-1.004.595-1.354.676-.227.052-.388.254-.388.487s.16.435.388.488ZM4.976,10.366c.377-.321.655-.675.86-1.016.196.34.467.693.837,1.014.175.151.363.286.562.404-.188.111-.377.245-.562.405-.37.32-.641.673-.837,1.014-.205-.341-.483-.695-.86-1.016-.175-.149-.363-.284-.562-.402.198-.119.387-.253.562-.402Z" />
          </g>
        </svg>
        <div className="flex flex-col items-start justify-center gap-x-3 sm:flex-row sm:items-center">
          <span className="font-bold leading-6">Create your ideal stay with AI!</span>
          <GenaiChat
            dialogTrigger={
              <button
                className="btn-text-link text-sm !leading-5"
                onClick={handleClick}
                type="button"
              >
                Try our AI Stay Planner
              </button>
            }
            guestId={guestId ?? 0}
            onDismiss={handleDismiss}
            isOpen={isOpen}
          />
        </div>
      </div>
    </div>
  );
};
