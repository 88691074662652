import { SkeletonLoader } from '@dx-ui/osc-skeleton-loader';

export const InitialConversationSkeletonLoader = () => (
  <div data-testid="initial-conversation-skeleton-loader" className="space-y-2">
    <SkeletonLoader className="h-6 w-1/5" color="bg-border" />
    <SkeletonLoader className="h-28 w-3/4" color="bg-border" />
    <div className="flex flex-col items-end space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0">
      <SkeletonLoader className="h-10 w-1/3" color="bg-border" />
      <SkeletonLoader className="h-10 w-1/5" color="bg-border" />
      <SkeletonLoader className="h-10 w-1/5" color="bg-border" />
      <SkeletonLoader className="h-10 w-1/4" color="bg-border" />
    </div>
  </div>
);
