import type * as React from 'react';
import type { LinkItem } from './alphabetized-items';
import { TabList, TabListButton, TabPanel, TabPanels, Tabs } from '@dx-ui/osc-tabs';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import Icon from '@dx-ui/osc-icon';

type RegionTabProps = {
  children: React.ReactNode;
  linkNameTag?: string;
  regions: LinkItem[];
  setRegionTabIndex: (tabIndex: number) => void;
};

export const RegionTabs = ({
  children,
  linkNameTag = '',
  regions,
  setRegionTabIndex,
}: RegionTabProps) => {
  const { t } = useTranslation('default-locations-page');
  const isAsiaTab = (regionName: string) => regionName.toLowerCase() === 'asia';
  return (
    <div className="p-4">
      <Tabs defaultActive="0" onTabChange={(index) => setRegionTabIndex(parseFloat(index || '0'))}>
        <TabList>
          {regions?.map((region, index) => (
            <TabListButton id={`${index}`} key={region.name}>
              {region.name}
            </TabListButton>
          ))}
        </TabList>
        <TabPanels>
          {regions?.map((region, regionIndex) => (
            <TabPanel className="py-4" id={`${regionIndex}`} key={region.name}>
              <div
                className={cx('flex pt-2', {
                  'justify-end': !isAsiaTab(region.name),
                  'justify-between': isAsiaTab(region.name),
                })}
              >
                {isAsiaTab(region.name) ? (
                  <div className="text-sm font-bold">{t('countryRegion')}</div>
                ) : null}
                <a
                  className="text-primary hover:text-primary-alt flex cursor-pointer text-sm font-semibold underline"
                  href={region?.uri || undefined}
                >
                  {region?.name} {linkNameTag}
                  <Icon name="arrow-right" className="-mt-1 pl-0.5" />
                </a>
              </div>
              {children}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};
