import { MetaData } from '../meta-data';
import Footer from '../../components/footer';
import { PlacesSearch } from '../places-search';
import { useTranslation } from 'next-i18next';
import { useDefaultLocationsPage, useGetMVTSelections } from '../../hooks';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { SearchHeader } from '../../components/search-header';
import { useInitializeConductrics } from '../../hooks/use-initialize-conductrics';
import { LocationsByAttributes } from './hotel-locations/locations-by-attributes';
import { LocationsByRegion } from './hotel-locations/locations-by-region';
import { TopMarketLocations } from './locations-destination-reels/top-market-locations';
import { TopPromoLocations } from './locations-destination-reels/top-promo-locations';
import { DestinationGuides } from './destination-guides/destination-guides';
import { AboutLocationsPage } from '../about-locations/about-locations-page';
import { AmenityTiles } from './amenity-tiles-mvt/amenity-tiles';
import { useIntersectionObserver } from 'usehooks-ts';
import { ContentBlocks } from './restyle-content-blocks-mvt/content-blocks';
import { RESTYLE_DEFAUlT_LOCATIONS_CONTENT_BLOCKS } from '../../constants';
import { handleOnLinkClick } from './restyle-content-blocks-mvt/content-block-utils';
import { GenAIBannerAndDialog } from './genai-banner-and-dialog/genai-banner-and-dialog';

export const DefaultLocationsPage = () => {
  const { t } = useTranslation(['default-locations-page', 'footer']);
  const { guestInfo, isLoading: isAuthLoading, isAuthenticated } = useAuth();

  // conductrics init
  useInitializeConductrics({ authLoading: isAuthLoading, guestInfo, isAuthenticated });
  const { aboutContentBlock, pageInterLinks, isGeoCodePageLoading } = useDefaultLocationsPage();
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 1,
    freezeOnceVisible: true,
    rootMargin: '0px 0px -10px 0px',
  });
  const { isMVTVariant: ContentBlockMVT } = useGetMVTSelections({
    agentId: RESTYLE_DEFAUlT_LOCATIONS_CONTENT_BLOCKS.agentId,
    isProvisional: isIntersecting,
    MVTVariants: ['b', 'c'],
  });
  return (
    <div className="flex flex-col">
      <MetaData
        brandCode="WW"
        description={t('default-locations-page:pageDescription')}
        pageTitle={t('default-locations-page:pageTitle')}
        path="/locations"
      />
      <SearchHeader isFluid />
      <main role="main">
        <PlacesSearch ctaText={t('default-locations-page:findHotel')} />
        <GenAIBannerAndDialog />
        <div className="container px-4 lg:px-8">
          <h2 className="text-primary heading-2xl sm:heading-4xl flex items-center py-4 leading-snug">
            {t('default-locations-page:destinationsYouWillLove')}
          </h2>
          <TopMarketLocations />
          <TopPromoLocations />
          <div ref={ref}>
            <DestinationGuides />
          </div>
          {/* NHCSEARCH-5262 Browse by amenity tiles */}
          <AmenityTiles hasScrolledToMVT={isIntersecting} />
        </div>
        <ContentBlocks
          MVTVariants={ContentBlockMVT}
          content={aboutContentBlock?.contentBlocks || []}
        />
        <LocationsByRegion />
        <LocationsByAttributes isLoading={isGeoCodePageLoading} pageInterLinks={pageInterLinks} />
        {/* NHCSEARCH-5305 restyled content blocks */}
        {!ContentBlockMVT.b && !ContentBlockMVT.c ? (
          <div onClick={handleOnLinkClick}>
            <AboutLocationsPage
              about={aboutContentBlock}
              aboutClassName="px-4 py-3 lg:px-8"
              contentBlockClassName="py-2 px-2"
              descriptionClassName="p-0"
            />
          </div>
        ) : null}
      </main>
      <Footer isFluid />
    </div>
  );
};
