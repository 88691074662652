import { sendReward } from '@dx-ui/framework-conductrics';
import { GOALS, env } from '../../../constants';
import type { TFunction } from 'i18next';
import { prefixPublicAssetUriWithAppPrefix } from '../../../utils';
const baseURL = env.OHW_BASE_URL;
const defaultItemLinkProps = {
  onClick: () => sendReward(GOALS.amenityModalCTAEngagement),
  isNewWindow: false,
};

const getRegionItems = (t: TFunction<'default-locations-page'>, locale: string) => {
  return [
    {
      id: 'north_america_tile',
      imageAltTxt: t('attributeTiles.regions.northAmerica.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/regions/north_america.webp'
      ),
      itemTitle: t('attributeTiles.regions.northAmerica.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.regions.northAmerica.LinkLabel'),
        url: `${baseURL}${locale}/locations/north-america`,
      },
      modalImageAltText: t('attributeTiles.regions.northAmerica.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/regions/north_america.webp'
      ),
      shortDescription: t('attributeTiles.regions.northAmerica.shortDescription'),
      segmentIds: [],
    },
    {
      id: 'europe_tile',
      imageAltTxt: t('attributeTiles.regions.europe.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/regions/europe.webp'),
      itemTitle: t('attributeTiles.regions.europe.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.regions.europe.LinkLabel'),
        url: `${baseURL}${locale}/locations/europe`,
      },
      modalImageAltText: t('attributeTiles.regions.europe.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/regions/europe.webp'),
      shortDescription: t('attributeTiles.regions.europe.shortDescription'),
      segmentIds: [],
    },
    {
      id: 'asia_tile',
      imageAltTxt: t('attributeTiles.regions.asia.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/regions/asia.webp'),
      itemTitle: t('attributeTiles.regions.asia.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.regions.asia.LinkLabel'),
        url: `${baseURL}${locale}/locations/asia`,
      },
      modalImageAltText: t('attributeTiles.regions.asia.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/regions/asia.webp'),
      shortDescription: t('attributeTiles.regions.asia.shortDescription'),
      segmentIds: [],
    },
    {
      id: 'central_america_tile',
      imageAltTxt: t('attributeTiles.regions.centralAmerica.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/regions/central_america.webp'
      ),
      itemTitle: t('attributeTiles.regions.centralAmerica.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.regions.centralAmerica.LinkLabel'),
        url: `${baseURL}${locale}/locations/central-america`,
      },
      modalImageAltText: t('attributeTiles.regions.centralAmerica.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/regions/central_america.webp'
      ),
      shortDescription: t('attributeTiles.regions.centralAmerica.shortDescription'),
      segmentIds: [],
    },
  ];
};

const getCountryItems = (t: TFunction<'default-locations-page'>, locale: string) => {
  return [
    {
      id: 'united_states_tile',
      imageAltTxt: t('attributeTiles.countries.unitedStates.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/country/usa.webp'),
      itemTitle: t('attributeTiles.countries.unitedStates.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.countries.unitedStates.LinkLabel'),
        url: `${baseURL}${locale}/locations/usa`,
      },
      modalImageAltText: t('attributeTiles.countries.unitedStates.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/country/usa.webp'),
      shortDescription: t('attributeTiles.countries.unitedStates.shortDescription'),
      segmentIds: [],
    },
    {
      id: 'united_kingdom_tile',
      imageAltTxt: t('attributeTiles.countries.unitedKingdom.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/country/united_kingdom.webp'
      ),
      itemTitle: t('attributeTiles.countries.unitedKingdom.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.countries.unitedKingdom.LinkLabel'),
        url: `${baseURL}${locale}/locations/united-kingdom`,
      },
      modalImageAltText: t('attributeTiles.countries.unitedKingdom.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/country/united_kingdom.webp'
      ),
      shortDescription: t('attributeTiles.countries.unitedKingdom.shortDescription'),
      segmentIds: [],
    },
    {
      id: 'canada_tile',
      imageAltTxt: t('attributeTiles.countries.canada.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/country/canada.webp'),
      itemTitle: t('attributeTiles.countries.canada.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.countries.canada.LinkLabel'),
        url: `${baseURL}${locale}/locations/canada`,
      },
      modalImageAltText: t('attributeTiles.countries.canada.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/country/canada.webp'),
      shortDescription: t('attributeTiles.countries.canada.shortDescription'),
      segmentIds: [],
    },
    {
      id: 'mexico_tile',
      imageAltTxt: t('attributeTiles.countries.mexico.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/country/mexico.webp'),
      itemTitle: t('attributeTiles.countries.mexico.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.countries.mexico.LinkLabel'),
        url: `${baseURL}${locale}/locations/mexico`,
      },
      modalImageAltText: t('attributeTiles.countries.mexico.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/country/mexico.webp'),
      shortDescription: t('attributeTiles.countries.mexico.shortDescription'),
      segmentIds: [],
    },
  ];
};

const getFeatureItems = (t: TFunction<'default-locations-page'>, locale: string) => {
  return [
    {
      id: 'indoor_pool_tile',
      imageAltTxt: t('attributeTiles.features.indoorPool.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/feature/indoor_pool.webp'),
      itemTitle: t('attributeTiles.features.indoorPool.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.features.indoorPool.LinkLabel'),

        url: `${baseURL}${locale}/locations/indoor-pool`,
      },
      modalImageAltText: t('attributeTiles.features.indoorPool.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/feature/indoor_pool.webp'
      ),
      shortDescription: t('attributeTiles.features.indoorPool.shortDescription'),
      segmentIds: [],
    },
    {
      id: 'beach_tile',
      imageAltTxt: t('attributeTiles.features.beach.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/feature/beach.webp'),
      itemTitle: t('attributeTiles.features.beach.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.features.beach.LinkLabel'),

        url: `${baseURL}${locale}/locations/beach`,
      },
      modalImageAltText: t('attributeTiles.features.beach.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/feature/beach.webp'),
      shortDescription: t('attributeTiles.features.beach.shortDescription'),
      segmentIds: [],
    },
    {
      id: 'outdoor_pool_tile',
      imageAltTxt: t('attributeTiles.features.outdoorPool.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/feature/outdoor_pool.webp'
      ),
      itemTitle: t('attributeTiles.features.outdoorPool.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.features.outdoorPool.LinkLabel'),

        url: `${baseURL}${locale}/locations/outdoor-pool`,
      },
      modalImageAltText: t('attributeTiles.features.outdoorPool.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/feature/outdoor_pool.webp'
      ),
      shortDescription: t('attributeTiles.features.outdoorPool.shortDescription'),
      segmentIds: [],
    },
    {
      id: 'spa_tile',
      imageAltTxt: t('attributeTiles.features.spa.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/feature/spa.webp'),
      itemTitle: t('attributeTiles.features.spa.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.features.spa.LinkLabel'),

        url: `${baseURL}${locale}/locations/spa`,
      },
      modalImageAltText: t('attributeTiles.features.spa.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/feature/spa.webp'),
      shortDescription: t('attributeTiles.features.spa.shortDescription'),
      segmentIds: [],
    },
  ];
};

const getHotelTypeItems = (t: TFunction<'default-locations-page'>, locale: string) => {
  return [
    {
      id: 'pet_friendly_tile',
      imageAltTxt: t('attributeTiles.hotelType.petFriendly.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/hotel-type/pet_friendly.webp'
      ),
      itemTitle: t('attributeTiles.hotelType.petFriendly.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.hotelType.petFriendly.LinkLabel'),

        url: `${baseURL}${locale}/locations/pet-friendly`,
      },
      modalImageAltText: t('attributeTiles.hotelType.petFriendly.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/hotel-type/pet_friendly.webp'
      ),
      shortDescription: t('attributeTiles.hotelType.petFriendly.shortDescription'),
      segmentIds: [],
    },
    {
      id: 'resort_tile',
      imageAltTxt: t('attributeTiles.hotelType.resort.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/hotel-type/resort.webp'),
      itemTitle: t('attributeTiles.hotelType.resort.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.hotelType.resort.LinkLabel'),

        url: `${baseURL}${locale}/locations/resorts`,
      },
      modalImageAltText: t('attributeTiles.hotelType.resort.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/hotel-type/resort.webp'
      ),
      shortDescription: t('attributeTiles.hotelType.resort.shortDescription'),
      segmentIds: [],
    },
    {
      id: 'all_inclusive_tile',
      imageAltTxt: t('attributeTiles.hotelType.allInclusive.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/hotel-type/all_inclusive.webp'
      ),
      itemTitle: t('attributeTiles.hotelType.allInclusive.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.hotelType.allInclusive.LinkLabel'),

        url: `${baseURL}${locale}/locations/all-inclusive`,
      },
      modalImageAltText: t('attributeTiles.hotelType.allInclusive.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/hotel-type/all_inclusive.webp'
      ),
      shortDescription: t('attributeTiles.hotelType.allInclusive.shortDescription'),
      segmentIds: [],
    },
    {
      id: 'luxury_tile',
      imageAltTxt: t('attributeTiles.hotelType.luxury.imageAltText'),
      imageUrl: prefixPublicAssetUriWithAppPrefix('/assets/amenity-tiles/hotel-type/luxury.webp'),
      itemTitle: t('attributeTiles.hotelType.luxury.itemTitle'),
      link: {
        ...defaultItemLinkProps,
        label: t('attributeTiles.hotelType.luxury.LinkLabel'),

        url: `${baseURL}${locale}/locations/luxury`,
      },
      modalImageAltText: t('attributeTiles.hotelType.luxury.imageAltText'),
      modalImageUrl: prefixPublicAssetUriWithAppPrefix(
        '/assets/amenity-tiles/hotel-type/luxury.webp'
      ),
      shortDescription: t('attributeTiles.hotelType.luxury.shortDescription'),
      segmentIds: [],
    },
  ];
};

export const getAmenityTiles = ({
  type,
  t,
  locale = 'en',
}: {
  type: 'region' | 'country' | 'feature' | 'hotel' | undefined;
  t: TFunction<'default-locations-page'>;
  locale?: string;
}) => {
  switch (type) {
    case 'region':
      return { items: getRegionItems(t, locale), heading: t('attributeTiles.browseRegion') };
    case 'country':
      return { items: getCountryItems(t, locale), heading: t('attributeTiles.browseCountry') };
    case 'feature':
      return { items: getFeatureItems(t, locale), heading: t('attributeTiles.browseFeature') };
    case 'hotel':
      return { items: getHotelTypeItems(t, locale), heading: t('attributeTiles.browseHotel') };

    default:
      return null;
  }
};

export const getAmenityMVTVariant = (variant: Record<string, boolean>) => {
  switch (true) {
    case variant.b:
      return 'region';

    case variant.c:
      return 'country';

    case variant.d:
      return 'feature';

    case variant.e:
      return 'hotel';

    default:
      break;
  }
};
