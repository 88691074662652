import { Dialog } from '@dx-ui/osc-dialog-v2';
import * as React from 'react';
import cx from 'classnames';
import type { FormDataStructure } from '@dx-ui/osc-form';
import { Form, FormConnector } from '@dx-ui/osc-form';
import type { UseFormReturn } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { GuestConversationGenAi, SendGenAiMessageVariables } from './gql/types';
import { CreateConversationGenAiDocument, SendGenAiMessageDocument } from './gql/types';
import type { DXError } from '@dx-ui/types-graphql';
import { Accordion } from '@dx-ui/osc-accordion';
import { InitialConversationSkeletonLoader } from './genai-chat.initial-conversation-skeleton-loader';
import { ChatBubbleMarkdown } from './genai-chat.chat-bubble-markdown';
import { ChatBubbleLoading } from './genai-chat.chat-bubble-loading';

export type GenaiChatProps = {
  guestId: number;
} & Pick<React.ComponentProps<typeof Dialog>, 'isOpen' | 'onDismiss' | 'dialogTrigger'>;

/** These are hardcoded values that will go away as backend becomes available */
const INITIAL_SUGGESTIONS = [
  'Choose a destination',
  'Find a Hotel',
  'Use Points',
  'Explore brands',
];

/**
 *
 * THE GENERAL AI CHAT COMPONENT IS CURRENTLY A PROOF OF CONCEPT. DO NOT USE THIS IN ANY APPLICATION UNTIL THE
 * COMPONENT IS MARKED AS "READY"
 */
export function GenaiChat(props: GenaiChatProps) {
  const [chatHistory, setChatHistory] = React.useState<GuestConversationGenAi[]>([]);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const lastMessageRef = React.useRef<HTMLLIElement>(null);
  const chatMutation = useMutation<
    GuestConversationGenAi,
    DXError,
    [typeof SendGenAiMessageDocument, SendGenAiMessageVariables]
  >({
    mutationKey: [SendGenAiMessageDocument],
    mutationFn: ([_document, _variables]) => {
      return new Promise<GuestConversationGenAi>((resolve) => {
        setTimeout(() => {
          resolve({
            id: crypto.randomUUID(),
            role: 'assistant',
            content: [{ message: 'This is a simulated response from the AI.', type: 'bubbles' }],
          });
        }, 2000);
      });
    },
  });
  const { data: initialConversationData, isLoading: isInitialConversationDataLoading } = useQuery<
    GuestConversationGenAi[]
  >({
    queryKey: [CreateConversationGenAiDocument, props.guestId],
    queryFn: () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve([
            {
              id: '1',
              content: [
                {
                  message: `<p>Hello! I’m Hilton’s AI assistant. How can I help you plan your stay?
          I'll ask a few questions and provide some recommendations for you.</p><br/><p>To get started, ask me a question or try one of these options:</p>`,
                  type: 'welcome',
                },
              ],
              role: 'assistant',
            },
          ]);
        }, 2000);
      });
    },
  });

  /** Set initial chat history once when query hook return initial greeting */
  React.useEffect(() => {
    if (!isInitialConversationDataLoading && initialConversationData && !chatHistory.length) {
      setChatHistory(initialConversationData);
    }
  }, [chatHistory, initialConversationData, isInitialConversationDataLoading]);

  /** scroll into view new chat message each time chat updates */
  React.useEffect(() => {
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory]);

  const handleSubmit = (data: FormDataStructure, { reset }: UseFormReturn) => {
    const chatContent: GuestConversationGenAi = {
      id: data['chat-input'],
      role: 'user',
      content: [{ message: data['chat-input'], type: 'bubbles' }],
    };
    setChatHistory((chatHistory) => [...chatHistory, chatContent]);

    const variables: SendGenAiMessageVariables = {
      conversationId: '2',
      sessionId: '1',
      guestId: props.guestId,
    };

    chatMutation.mutate([SendGenAiMessageDocument, variables], {
      onSuccess: (llmData: GuestConversationGenAi) => {
        setChatHistory((chatHistory) => [...chatHistory, llmData]);
        inputRef.current?.focus();
      },
    });

    reset();
  };

  const handleDismiss = () => {
    // Per current design chat is not persisted when modal is closed
    setChatHistory(initialConversationData ?? []);
    props.onDismiss?.();
  };

  return (
    <Dialog
      {...props}
      className="flex h-[calc(100%-28px)] flex-col"
      dialogClassName="h-full"
      onDismiss={handleDismiss}
      title="AI Stay Planner"
      size="xl"
    >
      <ol aria-live="polite" className="flex grow flex-col space-y-4 overflow-auto">
        {isInitialConversationDataLoading ? (
          <InitialConversationSkeletonLoader />
        ) : (
          chatHistory.map(({ role, content }, index) => {
            return content.map(({ message }) => (
              <>
                <ChatBubbleMarkdown
                  key={message}
                  chatRole={role}
                  ref={
                    index === chatHistory.length - 1 && !lastMessageRef ? lastMessageRef : undefined
                  }
                >
                  {message}
                </ChatBubbleMarkdown>
                {index === 0 ? (
                  <li
                    key="suggestedSearches"
                    className="flex flex-col items-end justify-between space-y-2 sm:flex-row sm:space-y-0"
                  >
                    {INITIAL_SUGGESTIONS.map((suggestion) => (
                      <button
                        className="btn btn-primary-outline w-fit px-4 py-2"
                        key={suggestion}
                        type="button"
                        onClick={() => {
                          const variables: SendGenAiMessageVariables = {
                            conversationId: '2',
                            sessionId: '1',
                            guestId: props.guestId,
                          };

                          const userResponse: GuestConversationGenAi = {
                            id: suggestion,
                            role: 'user',
                            content: [{ message: suggestion, type: 'bubbles' }],
                          };

                          setChatHistory((chatHistory) => [...chatHistory, userResponse]);

                          chatMutation.mutate([SendGenAiMessageDocument, variables], {
                            onSuccess: (llmData: GuestConversationGenAi) => {
                              setChatHistory((chatHistory) => [...chatHistory, llmData]);
                              inputRef.current?.focus();
                            },
                          });
                        }}
                      >
                        {suggestion}
                      </button>
                    ))}
                  </li>
                ) : null}
                {chatMutation.isPending && index === chatHistory.length - 1 ? (
                  <ChatBubbleLoading ref={lastMessageRef} />
                ) : null}
              </>
            ));
          })
        )}
      </ol>
      <div className="bg-bg sticky bottom-0 w-full pt-4">
        <Form
          className="bg-bg relative flex h-10 max-w-xl gap-x-4"
          onSubmit={handleSubmit}
          useFormProps={{ mode: 'onChange' }}
        >
          <FormConnector>
            {({ formState, register }) => {
              const { ref, ...rest } = register('chat-input', { required: true });
              return (
                <>
                  <label
                    className="bg-bg absolute -top-3 left-6 px-1 text-sm font-bold"
                    htmlFor="chat-input"
                  >
                    Type a response...
                  </label>
                  <input
                    {...rest}
                    autoComplete="off"
                    defaultValue=""
                    id="chat-input"
                    type="text"
                    className="grow rounded border p-2"
                    ref={(e) => {
                      ref(e);
                      //@ts-expect-error RHF has internal ref. so you have to assign your ref this way https://www.react-hook-form.com/faqs/#Howtosharerefusage
                      inputRef.current = e;
                    }}
                  />
                  <button
                    disabled={!formState.isValid || isInitialConversationDataLoading}
                    type="submit"
                    className={cx('btn btn-primary h-10 w-28 rounded-r p-2', {
                      'btn-disabled': !formState.isValid,
                    })}
                  >
                    Send
                  </button>
                </>
              );
            }}
          </FormConnector>
        </Form>
        <div className="flex justify-between py-2">
          <Accordion
            className="accordion-single"
            items={[
              {
                collapsedButtonAccessibleLabel: 'Show terms and conditions',
                collapsedButtonLabel: 'Terms and conditions',
                content: (
                  <div className="pt-1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                  </div>
                ),
                key: 'termsAndConditions',
                expandedButtonAccessibleLabel: 'Hide terms and conditions',
                expandedButtonLabel: 'Terms and conditions',
              },
            ]}
            type="single"
          />
          <div className="flex h-full items-start">
            <button
              className="btn btn-primary-link text-base"
              type="button"
              onClick={() => {
                setChatHistory(initialConversationData ?? []);
                inputRef.current?.focus();
              }}
            >
              Start Over
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default GenaiChat;
