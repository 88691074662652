import type * as React from 'react';
import type { LinkItem } from '../hotel-locations/alphabetized-items';
import { Accordion } from '@dx-ui/osc-accordion';
import Icon from '@dx-ui/osc-icon';

type AccordionSubPanelProps = {
  children: React.ReactNode;
  isExpanded?: boolean;
  linkItem?: LinkItem;
  linkNameTag?: string;
};

export const AccordionSubPanel = ({
  children,
  isExpanded,
  linkItem,
  linkNameTag = '',
}: AccordionSubPanelProps) => (
  <div className="text-base">
    <div className="border-border justify-start border-b-2 py-1">
      <Accordion
        className="accordion-stacked text-primary hover:text-primary-alt border-none py-2 font-semibold focus:shadow-none"
        defaultValue={isExpanded && linkItem?.name ? [linkItem?.name] : []}
        items={[
          {
            content: (
              <div>
                {linkItem?.uri && (
                  <div className="border-border flex justify-end border-t-2 py-1">
                    <a
                      className="text-primary hover:text-primary-alt flex cursor-pointer pt-2.5 text-sm font-bold underline"
                      href={linkItem?.uri || undefined}
                    >
                      {linkItem?.name} {linkNameTag}
                      <Icon name="arrow-right" className="-mt-1 pl-0.5" />
                    </a>
                  </div>
                )}
                {children}
              </div>
            ),
            collapsedButtonLabel: linkItem?.name,
            expandedButtonLabel: linkItem?.name,
            key: linkItem?.name ?? '',
          },
        ]}
        type="multiple"
        variant="stacked"
      />
    </div>
  </div>
);
