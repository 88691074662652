import cx from 'classnames';
import { useIsClient } from 'usehooks-ts';
export type LinkItem = { name: string; uri: string };
type AlphabetizedItem = { letter: string; list: LinkItem[] };

type AlphabetizedItemProps = {
  columnCount: number;
  itemTitle?: string;
  linkItems: LinkItem[];
};

export const AlphabetizedLinkItems = ({
  columnCount,
  itemTitle,
  linkItems,
}: AlphabetizedItemProps) => {
  const isClient = useIsClient();
  const getAlphabetizedList = (linkItems: LinkItem[]) => {
    const items = linkItems.reduce((acc: Record<string, AlphabetizedItem>, cur) => {
      const alphabet = cur.name.charAt(0).toUpperCase();
      if (!acc[alphabet]) acc[alphabet] = { letter: alphabet, list: [cur] };
      else acc[alphabet]?.list.push(cur);
      return acc;
    }, {});
    return items;
  };

  const alpabetizedItems = Object.values(getAlphabetizedList(linkItems));
  const itemsCount = alpabetizedItems?.reduce((acc, cur) => acc + cur.list.length + 1, 0);
  const numRows = Math.ceil(itemsCount / columnCount);

  const getFormatttedItems = (alpabetizedItemList: AlphabetizedItem[], numRows: number) => {
    const formattedItems: AlphabetizedItem[] = [];
    let rowIndex = 0;
    alpabetizedItemList.forEach(({ letter, list }) => {
      rowIndex = rowIndex + list.length + 1;
      formattedItems.push({ letter, list });
      if (rowIndex % numRows === numRows - 1) {
        formattedItems.push({ letter: '0', list: [] });
        rowIndex++;
      }
    });
    return formattedItems;
  };

  let formattedItems = getFormatttedItems([...alpabetizedItems], numRows);
  const formattedItemsCount = formattedItems?.reduce((acc, cur) => acc + cur?.list?.length + 1, 0);
  const newRowCount = Math.ceil(formattedItemsCount / columnCount);
  if (newRowCount > numRows)
    formattedItems = getFormatttedItems([...alpabetizedItems], newRowCount);

  const AlphabetizedLinkItemsContent = () => (
    <ul className="gap-x-8 py-4" style={{ columnCount }}>
      {formattedItems?.map(({ letter, list }, rowIndex) => (
        <li
          className={cx({ invisible: letter === '0' })}
          // eslint-disable-next-line react/no-array-index-key
          key={`header-${itemTitle?.replace(/\s+/g, '')}-${letter}-${rowIndex}`}
        >
          <h4 className={cx('block font-bold', { 'pt-4': rowIndex >= 1 })}>{letter}</h4>
          <ul>
            {list?.map((link: LinkItem) => (
              <li
                key={`details-${itemTitle?.replace(/\s+/g, '')}-${letter}-${link.name?.replace(
                  /\s+/g,
                  ''
                )}`}
              >
                <a
                  className="text-primary hover:text-primary-alt block hover:underline"
                  href={link.uri}
                >
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
  return isClient ? (
    <AlphabetizedLinkItemsContent />
  ) : (
    <noscript>
      <ul>
        {formattedItems?.map(({ letter, list }, rowIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`header-$${letter}-${rowIndex}`}>
            <ul>
              {list?.map((link: LinkItem, linkIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={`details-${linkIndex}-${link.name}`}>
                  <a href={link.uri}>{link.name}</a>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </noscript>
  );
};
