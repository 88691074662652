import * as React from 'react';
import cx from 'classnames';

const UserRole = ({ chatRole }: { chatRole: 'user' | 'assistant' }) => (
  <span className="text-text-alt text-xs leading-6">
    {chatRole === 'user' ? 'You' : 'Stay Planner'}
  </span>
);

const Triangle = ({ chatRole }: { chatRole: 'user' | 'assistant' }) => {
  const triangeConfig = {
    assistant: { fill: '#CFDBEA', dir: 'left' },
    user: { fill: '#E5E5E5', dir: 'right' },
  } as const;

  return (
    <svg
      aria-hidden
      className={cx('absolute top-7 size-8', {
        'rotate-180': triangeConfig[chatRole].dir === 'right',
      })}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="28"
      viewBox="0 0 24 28"
      fill="none"
    >
      <path
        d="M0.999998 15.732C-0.333335 14.9622 -0.333333 13.0378 1 12.268L20.5 1.00962C21.8333 0.239819 23.5 1.20207 23.5 2.74167V25.2583C23.5 26.7979 21.8333 27.7602 20.5 26.9904L0.999998 15.732Z"
        fill={triangeConfig[chatRole].fill}
      />
    </svg>
  );
};

const ChatBubbleInnerWrapper = ({
  chatRole,
  children,
}: {
  chatRole: 'user' | 'assistant';
  children: React.ReactNode;
}) => {
  const isUser = chatRole === 'user';
  const bgColor = isUser ? 'bg-[#E5E5E5]' : 'bg-[#CFDBEA]';
  const marginClass = isUser ? 'mr-[20px]' : 'ml-[20px]';

  return (
    <div className={cx('relative max-w-[80%] overflow-hidden text-ellipsis', marginClass)}>
      <UserRole chatRole={chatRole} />
      <div className={cx('rounded-sm px-4 py-2.5 text-base', bgColor)}>{children}</div>
    </div>
  );
};

export const ChatBubble = React.forwardRef<
  HTMLLIElement,
  { children: React.ReactNode; chatRole: 'user' | 'assistant' }
>(({ children, chatRole }, ref) => {
  return (
    <li ref={ref} className={cx('relative flex', { 'justify-end': chatRole === 'user' })}>
      <Triangle chatRole={chatRole} />
      <ChatBubbleInnerWrapper chatRole={chatRole}>{children}</ChatBubbleInnerWrapper>
    </li>
  );
});

ChatBubble.displayName = 'ChatBubble';
