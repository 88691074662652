import { sendReward } from '@dx-ui/framework-conductrics';
import { prefixPublicAssetUriWithAppPrefix } from '../../../utils';
import { GOALS } from '../../../constants';

export const contentBlockData = {
  americas: {
    imageAltTxtKey: 'contentBlocks.americas.imageAltText',
    imageUrl: prefixPublicAssetUriWithAppPrefix('/assets/content-blocks/americas.webp'),
    tabTitleKey: 'contentBlocks.americas.americasTab',
  },
  europe: {
    imageAltTxtKey: 'contentBlocks.europe.imageAltText',
    imageUrl: prefixPublicAssetUriWithAppPrefix('/assets/content-blocks/europe.webp'),
    tabTitleKey: 'contentBlocks.europe.europeTab',
  },
  asia: {
    imageAltTxtKey: 'contentBlocks.asia.imageAltText',
    imageUrl: prefixPublicAssetUriWithAppPrefix('/assets/content-blocks/asia.webp'),
    tabTitleKey: 'contentBlocks.asia.asiaTab',
  },
  middleEast: {
    imageAltTxtKey: 'contentBlocks.middleEast.imageAltText',
    imageUrl: prefixPublicAssetUriWithAppPrefix('/assets/content-blocks/middle-east.webp'),
    tabTitleKey: 'contentBlocks.middleEast.middleEastTab',
  },
} as const;
export const getContentBlockData = (contentTitle: string) => {
  switch (true) {
    case contentTitle.includes('Americas'):
      return contentBlockData.americas;
    case contentTitle.includes('Europe'):
      return contentBlockData.europe;
    case contentTitle.includes('Asia'):
      return contentBlockData.asia;
    case contentTitle.includes('Middle East'):
      return contentBlockData.middleEast;

    default:
      break;
  }
};

export const decodeHtmlEntities = (input: string): string => {
  return input
    .replace(/&#39;/g, "'")
    .replace(/&quot;/g, '"')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>');
};

export const handleOnLinkClick = (e: React.MouseEvent<HTMLDivElement>) => {
  const target = e.target as HTMLElement;
  if (target.tagName === 'A') sendReward(GOALS.seoContentBlockLinkClicked);
};
