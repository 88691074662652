import classNames from 'classnames';

export type SkeletonLoaderProps = {
  variant?: 'rectangular' | 'rounded' | 'circle';
  color?: string;
} & React.ComponentProps<'div'>;

/**
 * The `SkeletonLoader` component provides a placeholder skeleton UI that mimics the structure of the content being loaded.
 * It supports different variants and animations, allowing for customization to match the desired layout and design.
 */
export function SkeletonLoader({
  className,
  variant = 'rounded',
  color = 'bg-bg-alt',
  children,
  ...rest
}: SkeletonLoaderProps) {
  return (
    <div
      className={classNames(
        'motion-safe:animate-pulse',
        color,
        {
          'inline-block': children,
          'rounded-md': variant === 'rounded',
          'rounded-full': variant === 'circle',
          'rounded-none': variant === 'rectangular',
        },
        className
      )}
      data-testid="skeleton-loader"
      {...rest}
    >
      {children}
    </div>
  );
}

export default SkeletonLoader;
