import type { LocationContentBlock } from '@dx-ui/gql-types';
import { ContentBlock } from './content-block';
import { TabList, TabListButton, TabPanel, TabPanels, Tabs } from '@dx-ui/osc-tabs';
import { contentBlockData, getContentBlockData, handleOnLinkClick } from './content-block-utils';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';

export const ContentBlocks = ({
  MVTVariants,
  content,
}: {
  MVTVariants?: Record<string, boolean>;
  content: LocationContentBlock[];
}) => {
  const { b: varB, c: varC } = MVTVariants ?? {};
  const { t } = useTranslation('default-locations-page');
  if (!varB && !varC) return null;

  return varC ? (
    content.map((contentData, i) => {
      const isReverseLayout = i % 2 !== 0;
      return (
        <div
          className={cx('lg:py-10', {
            'lg:bg-bg-light': isReverseLayout,
            '!py-0': i === 0,
          })}
          key={contentData.title}
          onClick={handleOnLinkClick}
        >
          <div
            className={cx('container max-lg:pt-4', {
              'bg-bg-light': isReverseLayout,
            })}
          >
            <ContentBlock
              content={contentData}
              key={contentData.title}
              isReverseLayout={isReverseLayout}
            />
          </div>
        </div>
      );
    })
  ) : (
    <div className="container" onClick={handleOnLinkClick}>
      <ContentBlock content={content[0]} key={content[0]?.title} />
      <Tabs defaultActive={contentBlockData.americas.tabTitleKey}>
        <TabList className="border-border-alt border-b">
          {content.slice(1).map((contentData) => {
            const { tabTitleKey } = getContentBlockData(contentData.title) ?? {};
            return (
              <TabListButton
                id={tabTitleKey}
                key={`${tabTitleKey}-list-button`}
                className="hover:text-primary focus:text-primary grow cursor-pointer appearance-none whitespace-nowrap px-4 py-2 text-base font-bold md:whitespace-normal md:text-2xl"
                activeClassName="border-b-primary text-primary border-b-4"
                inactiveClassName="text-text-alt"
              >
                {tabTitleKey ? t(tabTitleKey) : ''}
              </TabListButton>
            );
          })}
        </TabList>
        <TabPanels>
          {content.slice(1).map((contentData) => {
            const { tabTitleKey } = getContentBlockData(contentData.title) ?? {};

            return (
              <TabPanel className="py-4" id={tabTitleKey} key={`${tabTitleKey}-tab-panel`}>
                <ContentBlock content={contentData} key={contentData.title} />
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </div>
  );
};
